import axiosAPI from "@/api/axios-api";
import { saveAs } from "file-saver";
import { nft_scheme } from "../scheme/nft-scheme";
import { defaultLogo } from "@/utils";
import { REPORT_URL } from "@/api/index";

export default {
  data() {
    return {
      themes: { 0: "theme-light", 1: "theme-dark", 2: "theme-blue" },
      reportForm: null,
      notes: {
        answer: "",
        textarea: "",
      },
      selected: "",
      submitButtonMessage: "Submit",
      sendingError: false,
      avatarUrl: "",
      schemeCounter: [0, 0],
      schemeQuestionList: nft_scheme,
      schemeCategory: {
        development_team: [],
        community: [],
        mint_metrics: [],
        secondary_market: [],
      },
      schemeProgress: [],
      categoryVisible: [1, 1, 1, 1],
      currentQuestion: [],
      answered: [],
      helpers: {
        purpose: false,
        evaluate: false,
        example: false,
        sidebar: true,
      },
      reportCreated: false,
      reportLink: "",
      beforeEncode: [],
      summaryVisible: false,
      themeState: [],
      chipData: [],
      defaultLogo: defaultLogo,
      saveInterval: null,
    };
  },
  created() {
    this.changeTheme();
    this.setupCounter();
    this.setupQuestions();
    this.changeCurrentQuestion();
    this.changeListQuestion();
    this.getAuditInfo();
    this.setupAvatar();
    this.savingSetup();
  },
  watch: {
    summaryVisible() {
      this.reportCreated = false;
    },
  },

  mounted() {
    const value = localStorage.getItem("nft");

    if (value) {
      const [questions, general, progress] = JSON.parse(value);

      this.schemeQuestionList = questions;
      this.answered = questions;
      this.reportForm = general;
      this.schemeProgress = progress;
      this.$store.commit("configureNFT", general);
      this.clickCurrentQuestion(1);
    }
  },

  beforeUnmount() {
    clearInterval(this.saveInterval);
    console.log("unmount");
  },
  methods: {
    savingSetup() {
      this.saveInterval = setInterval(() => {
        const drafts = [this.answered, this.reportForm, this.schemeProgress];

        localStorage.setItem("nft", JSON.stringify(drafts, null, 4));

        console.info("saved");
      }, 1 * 1000);
    },
    changeTheme() {
      this.themeState[0] < 2 ? this.themeState[0]++ : (this.themeState[0] = 0);

      localStorage.setItem("theme", this.themeState[0]);

      document.documentElement.className = this.themes[this.themeState[0]];
    },

    setupCounter() {
      this.schemeCounter[1] = this.schemeQuestionList.length;
    },
    setupQuestions() {
      this.answered = this.schemeQuestionList;
    },
    saveDraftReport() {
      this.reportForm["version"] = "4.0";

      const drafts = [this.answered, this.reportForm, this.schemeProgress];

      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

      const time = today.getHours() + "h-" + today.getMinutes() + "m-";

      const fileName = `${this.reportForm.project_name.toLowerCase().trim()}-${
        date + "-" + time
      }.json`;

      const fileToSave = new Blob([JSON.stringify(drafts, null, 4)], {
        type: "application/json",
      });

      saveAs(fileToSave, fileName);
    },
    handleFileUpload(event) {
      const reader = new FileReader();
      reader.readAsText(event.target.files[0]);
      reader.onload = (e) => {
        const [questions, general, progress] = JSON.parse(e.target.result);

        this.schemeQuestionList = questions;
        this.answered = questions;
        this.reportForm = general;
        this.schemeProgress = progress;

        console.log(general);

        this.$store.commit("configureNFT", general);
        this.clickCurrentQuestion(1);
      };
    },
    setNotesAnswer(element) {
      this.notes.answer = element;
    },
    summaryLayout() {
      this.summaryVisible = !this.summaryVisible;
    },
    remainingQuestion() {
      let remaining = [];

      for (const element of this.answered) {
        if (element.answer === 405) {
          this.answered[element.id - 1].answer = null;
        }

        if (element.answer === null) {
          remaining.push({
            id: element.id,
            a: element.tag,
            b: element.name,
            c: element.category,
          });
        }
      }

      if (remaining.length === 0) {
        return [];
      } else {
        return remaining;
      }
    },
    splitPurpose() {
      return this.currentQuestion[0].purpose.split(/\r?\n/);
    },
    nextGlassButton() {
      this.currentQuestion[0].answer = 405;
    },
    setupAvatar() {
      const hashCode = function (s) {
        return s.split("").reduce(function (a, b) {
          a = (a << 5) - a + b.charCodeAt(0);
          return a & a;
        }, 0);
      };
      const base = this.reportForm.nickname + this.reportForm.nickname.length;

      this.avatarUrl = `https://api.dicebear.com/7.x/identicon/svg?scale=100&seed=${hashCode(
        base
      )}`;
    },
    getAuditInfo() {
      this.reportForm = this.$store.getters.getAuditInfoNFT;
      this.reportForm["nid"] = this.createRandomString(20);
    },
    changeCurrentQuestion() {
      this.currentQuestion.push(this.schemeQuestionList[this.schemeCounter[0]]);
    },
    changeAnswerQuestion(answer) {
      this.currentQuestion[0].answer = answer;
    },
    cleanCurrentQuestion() {
      this.currentQuestion = [];
    },
    dependencyTree() {
      if (this.answered[23].answer === 0) {
        this.answered[23].answer = 0;
        this.answered[24].answer = 0;
        this.answered[25].answer = 0;
        this.answered[26].answer = 0;
        this.answered[27].answer = 0;
      }
    },
    nextAnswerQuestion() {
      this.dependencyTree();
      this.updateProgress();
      this.answered[this.schemeCounter[0]] = this.currentQuestion.pop();
      this.schemeCounter[0] += 1;
      this.changeCurrentQuestion();
    },
    backAnswerQuestion() {
      this.cleanCurrentQuestion();
      this.schemeCounter[0] -= 1;
      this.currentQuestion.push(this.answered[this.schemeCounter[0]]);
    },
    clickCurrentQuestion(num) {
      let number = num - 1;
      for (const n in this.answered) {
        if (n.id === number) {
          this.cleanCurrentQuestion();
          this.currentQuestion.push(this.answered[number]);
          this.schemeCounter[0] = this.currentQuestion[0].id - 1;
        }
        if (n.id != number) {
          this.cleanCurrentQuestion();
          this.currentQuestion.push(this.schemeQuestionList[number]);
          this.schemeCounter[0] = this.currentQuestion[0].id - 1;
        }
      }
    },
    addAnsweredQuestion() {
      this.answered[this.schemeCounter[0]] = this.currentQuestion[0];
    },
    createNewReport() {
      this.dependencyTree();
      this.updateProgress();
      this.addAnsweredQuestion();
      this.sendNewReport();
    },
    updateProgress() {
      return this.currentQuestion[0].answer
        ? this.schemeProgress.push(this.currentQuestion[0].id)
        : false;
    },
    async sendNewReport() {
      this.beforeEncode = [];

      this.sendingError = false;

      this.reportLink = "";

      this.reportForm["charts"] = {
        community: {
          twitter: {
            id: 11,
            value: this.answered[11 - 1].input || "0",
          },
          discord: {
            id: 13,
            value: this.answered[13 - 1].input || "0",
          },
        },
      };

      this.reportForm["notes"] = this.notes;

      this.answered.forEach((e) => {
        this.beforeEncode.push({
          id: e.id,
          answer: e.answer,
          textarea: e.textarea,
          input: e.input || "",
        });
      });

      try {
        const params = {
          scheme: "nft",

          project_name: this.reportForm.project_name.trim().toLowerCase(),

          description: this.reportForm.description.trim().toLowerCase(),

          website: this.reportForm.website.trim().toLowerCase(),

          category: this.reportForm.category.trim().toLowerCase(),

          author: this.reportForm.nickname.trim().toLowerCase(),

          project_logo: this.reportForm.project_logo,

          discord: this.reportForm.discord.trim().toLowerCase(),

          twitter: this.reportForm.twitter.trim().toLowerCase(),

          number_in_circulation: this.reportForm.number_in_circulation
            .trim()
            .toLowerCase(),

          whitelist: this.reportForm.whitelist.trim().toLowerCase(),

          number_per_mint: this.reportForm.number_per_mint.trim().toLowerCase(),

          mint_date: this.reportForm.mint_date.trim().toLowerCase(),

          nid: this.reportForm.nid,

          charts: this.reportForm.charts,

          notes: this.reportForm.notes,

          data: this.beforeEncode,
        };

        console.log(params);

        this.submitButtonMessage = "Uploading";

        document.getElementById("create-report").disabled = true;

        await axiosAPI
          .post("/api/dyortool/create-nft", params)
          .then((response) => {
            console.log(response.data);
            this.reportCreated = true;
            this.reportLink = `${REPORT_URL}/report/${response.data.payload[0].id}`;
            this.submitButtonMessage = "Create Report";
          })
          .catch((error) => {
            console.error(error.response);
            this.sendingError = error.response.data.errors;
            this.submitButtonMessage = "Create Report";
            document.getElementById("create-report").disabled = false;
          });
      } catch (error) {
        console.error(error);
      }
    },

    checkCompleteness() {
      let isComplete = false;
      let checkAnswers = [];
      this.answered.forEach((e) => checkAnswers.push(e.answer));
      if (checkAnswers.includes(null)) {
        isComplete = false;
      }
      if (!checkAnswers.includes(null)) {
        isComplete = true;
      }
      return isComplete;
    },
    createRandomString(length) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    },
    removeByIndex(array, item) {
      let e = array.indexOf(item);

      if (e !== -1) {
        array.splice(e, 1);
      }
    },
    backToWorksteps() {
      this.currentQuestion[0].answer = null;
      this.removeByIndex(this.schemeProgress, this.currentQuestion[0].id);
    },
    knowWorkProgress(step) {
      let acc = 0;
      this.schemeCategory[step].forEach((s) => {
        this.schemeProgress.includes(s.id) ? (acc += 1) : false;
      });
      return acc;
    },
    changeListQuestion() {
      for (const element of this.schemeQuestionList) {
        switch (element.category) {
          case "Development Team":
            this.schemeCategory["development_team"].push({
              id: element.id,
              name: element.name,
            });
            break;
          case "Community":
            this.schemeCategory["community"].push({
              id: element.id,
              name: element.name,
            });
            break;
          case "Mint Metrics":
            this.schemeCategory["mint_metrics"].push({
              id: element.id,
              name: element.name,
            });
            break;

          case "Secondary Market":
            this.schemeCategory["secondary_market"].push({
              id: element.id,
              name: element.name,
            });
            break;

          default:
            break;
        }
      }
    },
  },
};
