const env = "prod";

const hostURL = {
  dev: "https://dyortool.dev",
  prod: "https://dyortool.io",
};

const apiURL = {
  dev: "https://dyortool.dev",
  prod: "https://dyortool.io",
};

const reportURL = {
  dev: "https://audits.dyortool.dev",
  prod: "https://audits.dyortool.io",
};

const HOST_URL = hostURL[env];

const API_URL = apiURL[env];

const REPORT_URL = reportURL[env];

export { env, HOST_URL, API_URL, REPORT_URL };
