import axios from "axios";
import { API_URL } from './index';

axios.defaults.withCredentials = true;


const axiosAPI = axios.create({
  baseURL: API_URL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Credentials": true,
  },
});

export default axiosAPI;
