<template>
  <div
    class="css-mf-wrap"
    :class="{ active: summaryVisible || helpers.purpose }"
  >
    <header class="css-w-p-her">
      <router-link to="/" style="display: flex; align-items: baseline">
        <img id="logo-blue" src="../assets/logo.svg" alt="" />
      </router-link>

      <div class="css-work-tool-haw">
        <button class="css-3489" @click="changeTheme()">
          <i
            class="pi"
            :class="{
              'pi-sun': themeState[0] === 0,
              'pi-moon': themeState[0] === 1,
              'pi-moon b': themeState[0] === 2,
            }"
            />
        </button>

        <div class="css-8801">
          {{ selected }}
          <label
            for="filex"
            class="css-8802"
            style="
              background: transparent;
              border: 1px solid var(--border-a);
              color: var(--text-a);
            "
          >
            Load Draft
          </label>
          <input
            style="display: none"
            type="file"
            id="filex"
            @change="handleFileUpload($event)"
            accept=".json"
          />
          <label
            class="css-8802"
            style="display: initial"
            @click="saveDraftReport()"
          >
            Save
          </label>
        </div>
        <div class="css-identicon-wrp">
          <div class="css-identicon" id="identicon">
            <img :src="avatarUrl" alt="" />
          </div>
        </div>
        <span class="css-identicon-wrx">{{ reportForm.nickname }}</span>
      </div>
    </header>

    <div class="css-wt-wrap">
      <div class="css-w-p-wpr">
        <div class="css-w-p-wrap">
          <div class="css-w-p-title">
            <b>Audit progress</b>
          </div>
          <div class="css-w-p-arw">
            <div class="css-w-p-ari">
              <div class="css-w-p-awp">
                <img :src="reportForm.project_logo || defaultLogo" alt="" />
              </div>
            </div>
          </div>
          <div class="css-w-p-legend">
            <div class="css-w-p-name">
              <b>{{ reportForm.project_name }}</b>
            </div>
            <div class="css-w-p-tag">
              <span>{{ reportForm.category }}</span>
            </div>
            <div class="css-w-p-tag" style="text-transform: lowercase">
              <span>{{ reportForm.website }}</span>
            </div>
          </div>
          <div
            class="css-w-p-sbe"
            @click="categoryVisible[0] = !categoryVisible[0]"
          >
            <svg
              class="css-w-p-dar"
              :class="{ active: !categoryVisible[0] }"
              viewBox="0 0 1024 1024"
              data-v-365b8594=""
            >
              <path
                fill="currentColor"
                d="M340.864 149.312a30.592 30.592 0 000 42.752L652.736 512 340.864 831.872a30.592 30.592 0 000 42.752 29.12 29.12 0 0041.728 0L714.24 534.336a32 32 0 000-44.672L382.592 149.376a29.12 29.12 0 00-41.728 0z"
              ></path>
            </svg>

            <b>Development Team</b>
            <span
              >{{ knowWorkProgress("development_team") }} /
              {{ schemeCategory["development_team"].length }}
            </span>
          </div>
          <ul class="css-w-p-ul" :class="{ active: categoryVisible[0] }">
            <li
              v-for="category in schemeCategory['development_team']"
              :key="category"
              class="css-w-p-uli"
              @click="
                clickCurrentQuestion(category.id) & (summaryVisible = false)
              "
            >
              <i
                v-if="schemeProgress.includes(category.id)"
                class="pi pi-check"
              ></i>
              <span>{{ category.name }} </span>
            </li>
          </ul>
          <div
            class="css-w-p-sbe"
            @click="categoryVisible[1] = !categoryVisible[1]"
          >
            <svg
              class="css-w-p-dar"
              :class="{ active: !categoryVisible[1] }"
              viewBox="0 0 1024 1024"
              data-v-365b8594=""
            >
              <path
                fill="currentColor"
                d="M340.864 149.312a30.592 30.592 0 000 42.752L652.736 512 340.864 831.872a30.592 30.592 0 000 42.752 29.12 29.12 0 0041.728 0L714.24 534.336a32 32 0 000-44.672L382.592 149.376a29.12 29.12 0 00-41.728 0z"
              ></path>
            </svg>
            <b>Tokenomics</b>
            <span
              >{{ knowWorkProgress("tokenomics") }} /
              {{ schemeCategory["tokenomics"].length }}</span
            >
          </div>
          <ul class="css-w-p-ul" :class="{ active: categoryVisible[1] }">
            <li
              v-for="category in schemeCategory['tokenomics']"
              :key="category"
              class="css-w-p-uli"
              @click="
                clickCurrentQuestion(category.id) & (summaryVisible = false)
              "
            >
              <i
                v-if="schemeProgress.includes(category.id)"
                class="pi pi-check"
              ></i>
              <span>{{ category.name }} </span>
            </li>
          </ul>
          <div
            class="css-w-p-sbe"
            @click="categoryVisible[2] = !categoryVisible[2]"
          >
            <svg
              class="css-w-p-dar"
              :class="{ active: !categoryVisible[2] }"
              viewBox="0 0 1024 1024"
              data-v-365b8594=""
            >
              <path
                fill="currentColor"
                d="M340.864 149.312a30.592 30.592 0 000 42.752L652.736 512 340.864 831.872a30.592 30.592 0 000 42.752 29.12 29.12 0 0041.728 0L714.24 534.336a32 32 0 000-44.672L382.592 149.376a29.12 29.12 0 00-41.728 0z"
              ></path>
            </svg>
            <b>Community</b>
            <span
              >{{ knowWorkProgress("community") }} /
              {{ schemeCategory["community"].length }}</span
            >
          </div>
          <ul class="css-w-p-ul" :class="{ active: categoryVisible[2] }">
            <li
              v-for="category in schemeCategory['community']"
              :key="category"
              class="css-w-p-uli"
              @click="
                clickCurrentQuestion(category.id) & (summaryVisible = false)
              "
            >
              <i
                v-if="schemeProgress.includes(category.id)"
                class="pi pi-check"
              ></i>
              <span>{{ category.name }} </span>
            </li>
          </ul>
          <div
            class="css-w-p-sbe"
            @click="categoryVisible[3] = !categoryVisible[3]"
          >
            <svg
              class="css-w-p-dar"
              :class="{ active: !categoryVisible[3] }"
              viewBox="0 0 1024 1024"
              data-v-365b8594=""
            >
              <path
                fill="currentColor"
                d="M340.864 149.312a30.592 30.592 0 000 42.752L652.736 512 340.864 831.872a30.592 30.592 0 000 42.752 29.12 29.12 0 0041.728 0L714.24 534.336a32 32 0 000-44.672L382.592 149.376a29.12 29.12 0 00-41.728 0z"
              ></path>
            </svg>
            <b>Trading Metrics</b>
            <span
              >{{ knowWorkProgress("trading_metrics") }} /
              {{ schemeCategory["trading_metrics"].length }}</span
            >
          </div>
          <ul class="css-w-p-ul" :class="{ active: categoryVisible[3] }">
            <li
              v-for="category in schemeCategory['trading_metrics']"
              :key="category"
              class="css-w-p-uli"
              @click="
                clickCurrentQuestion(category.id) & (summaryVisible = false)
              "
            >
              <i
                v-if="schemeProgress.includes(category.id)"
                class="pi pi-check"
              ></i>
              <span>{{ category.name }} </span>
            </li>
          </ul>

          <div class="css-w-p-scc"></div>
        </div>
      </div>
      <div
        class="css-wq-wrap"
        :class="{ active: summaryVisible || helpers.purpose }"
      >
        <template v-if="helpers.purpose">
          <div class="css-wq-fpl" @click="helpers.purpose = !helpers.purpose">
            <div class="css-wq-fps">
              <div class="css-wq-fph">
                <div>
                  <span> Why This Matters?</span>
                </div>
                <button>
                  <i class="pi pi-times"></i>
                </button>
              </div>
              <div class="css-wq-fpx">
                <span
                  class="css-wq-fpz"
                  v-for="item in splitPurpose()"
                  :key="item"
                >
                  {{ item }}
                </span>
                <div class="css-wq-fxp">
                  <div>SCATDAO</div>
                  <span>www.scatdao.com</span>
                </div>
                <div class="css-wq-cso"></div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="summaryVisible">
          <div class="css-wq-fpl">
            <div class="css-wq-fyy">
              <div class="css-wq-fph">
                <div>
                  <span style="margin-right: 0.5rem">Summary</span>
                  <i class="pi pi-folder"></i>
                </div>
                <button @click="summaryVisible = !summaryVisible">
                  <i class="pi pi-times"></i>
                </button>
              </div>

              <div class="css-wq-fpx">
                <div class="css-wq-fyx">
                  <span class="css-wq-fzz">Remaining questions</span>
                  <span>{{ remainingQuestion().length }}</span>
                </div>
                <div
                  class="css-wq-fpb"
                  v-for="element in remainingQuestion()"
                  :key="element"
                  @click="
                    clickCurrentQuestion(element.id) &
                      (summaryVisible = !summaryVisible)
                  "
                >
                  <span> {{ element.a }} </span> <span> {{ element.b }} </span>
                  <span> {{ element.c }} </span>

                  <span> <i class="pi pi-arrow-circle-left"></i></span>
                </div>
                <template v-if="remainingQuestion().length === 0">
                  <div class="css-wq-fcc">
                    <div class="css-wq-fsc">
                      <span class="css-wq-koc">
                        What was your overall impression of the project?
                      </span>
                    </div>
                    <div class="css-wq-fko">
                      <button
                        @click="setNotesAnswer('positive')"
                        class="positiveb"
                        :class="{ active: notes.answer === 'positive' }"
                      >
                        Positive
                      </button>
                      <button
                        @click="setNotesAnswer('negative')"
                        class="negativeb"
                        :class="{ active: notes.answer === 'negative' }"
                      >
                        Negative
                      </button>
                      <button
                        @click="setNotesAnswer('neutral')"
                        class="neutralb"
                        :class="{ active: notes.answer === 'neutral' }"
                      >
                        Neutral
                      </button>
                    </div>
                  </div>
                  <div class="css-wq-fcc" style="margin-top: 1rem">
                    After researching all of these different areas, you will
                    usually have a strong feeling or impression of a project
                    that will influence your decision to invest in it or use it.
                    We wanted to leave a space where you can share that with
                    others. There are no points assigned, you do not have to
                    write anything. Literally anything goes here.
                  </div>

                  <div class="css-wq-cko">
                    <textarea
                      v-model="notes.textarea"
                      placeholder="Final Thoughts..."
                      />
                  </div>
                </template>
              </div>

              <div class="css-wq-xsc">
                <template v-if="remainingQuestion().length !== 0">
                  <div class="css-wq-xcq">
                    <span>Please complete the remaining questions.</span>
                  </div>
                </template>

                <template v-if="remainingQuestion().length === 0">
                  <div class="css-wq-bfw">
                    <template v-if="sendingError">
                      <div class="css-wq-xcq">
                        <span
                          >There was a problem with the request, please try
                          again</span
                        >
                        {{ sendingError }}
                      </div>
                    </template>
                    <template v-if="!reportCreated">
                      <button
                        class="css-wq-xkx"
                        id="create-report"
                        @click="createNewReport()"
                      >
                        {{ submitButtonMessage }}
                      </button></template
                    >
                    <template v-if="reportCreated">
                      <button class="css-wq-xkx" id="css-blue-button">
                        <a
                          :href="reportLink"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Go to Report</a
                        >
                      </button>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
        <div class="css-wq-top">
          <div
            class="css-wq-back"
            v-for="item in currentQuestion"
            :key="item"
            :class="{ active: item.answer != null }"
            @click="backToWorksteps()"
          >
            <div>
              <svg
                viewBox="0 0 1024 1024"
                data-v-066465b6=""
                width="24"
                style="transform: rotate(180deg)"
              >
                <path
                  fill="var(--text-a)"
                  d="M754.752 480H160a32 32 0 1 0 0 64h594.752L521.344 777.344a32 32 0 0 0 45.312 45.312l288-288a32 32 0 0 0 0-45.312l-288-288a32 32 0 1 0-45.312 45.312L754.752 480z"
                ></path>
              </svg>
            </div>
          </div>
          <div class="css-wq-id" v-for="item in currentQuestion" :key="item">
            #{{ item.tag }}
          </div>
        </div>

        <div class="css-wq-page">
          <div>
            <span
              class="css-wq-pag"
              v-for="item in currentQuestion"
              :key="item"
              >{{ item.id }}</span
            ><span class="css-wq-div">/</span>{{ schemeCounter[1] }}
          </div>
        </div>

        <div class="css-wq-legend" v-for="item in currentQuestion" :key="item">
          {{ item.question }}
        </div>
        <div class="css-wq-answer" v-for="item in currentQuestion" :key="item">
          <template v-if="item.answer != null">
            <div class="css-wq-suggest">Select an option</div>
          </template>
          <template v-if="item.answer === null">
            <div class="css-wq-note">
              <b>Worksteps:</b>
              <div v-for="step in item.worksteps" :key="step">
                {{ step }}
              </div>
            </div>
          </template>

          <template v-if="item.answer != null">
            <div class="css-wq-note">
              <b>Last step:</b>
              <div>Select an option according to the information collected</div>
            </div>
          </template>
          <template v-if="item.answer === null">
            <div class="css-wq-panel">
              <button
                class="css-wq-panel-b"
                @click="helpers.purpose = !helpers.purpose"
              >
                <i class="pi pi-book"/>
              </button>

              <button
                class="css-wq-panel-b"
                id="css-wq-panel-bl"
                @click="nextGlassButton()"
              >
                <img src="../assets/glass.svg" alt="" />
                <span class="dot"/>
              </button>
              <template v-if="!helpers.evaluate">
                <div
                  class="css-wq-got"
                  @click="helpers.evaluate = !helpers.evaluate"
                >
                  <div class="box box--left"></div>
                  <div class="css-wq-gtt">
                    <i class="pi pi-info-circle"></i>
                    <span>Click here to evaluate the question.</span>
                  </div>
                </div>
              </template>
            </div>
          </template>
          <template v-if="item.answer === null">
            <div class="css-wq-pta">
              <textarea
                class="css-wq-tex"
                v-model="currentQuestion[0].textarea"
              ></textarea>

              <template
                v-if="
                  item.id === 17 ||
                  item.id === 19 ||
                  item.id === 21 ||
                  item.id === 23
                "
              >
                <div class="css-wq-thl">
                  <div class="css-wq-txs">
                    <input
                      v-model="currentQuestion[0].input"
                      class="css-wq-tss"
                      type="text"
                      placeholder="Nº members or followers"
                    />
                  </div>
                </div>
              </template>

              <template v-if="item.id === 10">
                <div class="css-wq-thl">
                  <div class="css-wq-txs txs">
                    <Chips
                      @click="helpers.sidebar = true"
                      @remove="onChipRemove"
                      @add="onChipAdded"
                      :allowDuplicate="false"
                      v-model="chartData.labels"
                      separator=","
                    />

                    <Sidebar
                      v-model:visible="helpers.sidebar"
                      :modal="false"
                      position="right"
                    >
                      <Chart
                        type="doughnut"
                        :data="chartData"
                        :options="lightOptions"
                      />

                      <span
                        >Total
                        {{
                          chartData.datasets[0].data.length
                            ? chartData.datasets[0].data.reduce((a, b) => a + b)
                            : 0
                        }}</span
                      >
                      <template
                        v-for="(label, index) in chartData.labels"
                        :key="index"
                      >
                        <div class="css-a278">
                          <label for="c8a9">
                            <span
                              >{{ label }} -
                              {{ chartData.datasets[0].data[index] }} %</span
                            >
                          </label>
                          <input
                            id="c8a9"
                            class="css-628c"
                            v-model="chartData.datasets[0].data[index]"
                            type="number"
                          />
                        </div>
                      </template>
                    </Sidebar>
                  </div>
                </div>
              </template>
            </div>
          </template>

          <template v-for="option in item.options" :key="option">
            <div
              class="css-wq-answer-item"
              :class="{ active: item.answer === option.id }"
              v-if="item.answer != null"
            >
              <label
                class="control control--checkbox"
                @click="changeAnswerQuestion(option.id)"
              >
                <div class="css-wq-item-label">{{ option.name }}</div>
              </label>
            </div>
          </template>
        </div>

        <div class="css-wq-bpf">
          <div class="css-wq-nav">
            <div
              class="css-wq-arrow"
              id="left-arrow"
              @click="schemeCounter[0] > 0 ? backAnswerQuestion() : false"
            >
              <i class="pi pi-chevron-left"></i>
              <span>Back</span>
            </div>
            <template v-if="currentQuestion[0].id < schemeQuestionList.length">
              <div
                class="css-wq-arrow"
                id="right-arrow"
                @click="
                  schemeCounter[0] < schemeCounter[1] - 1
                    ? nextAnswerQuestion()
                    : false
                "
              >
                <span>Next</span>
                <i class="pi pi-chevron-right"></i>
              </div>
            </template>
            <template
              v-if="currentQuestion[0].id === schemeQuestionList.length"
            >
              <div
                class="css-wq-arrow"
                id="right-arrow"
                @click="summaryLayout()"
              >
                <span>Next</span>
                <i class="pi pi-chevron-right"/>
              </div>
            </template>
          </div>
        </div>
        <div class="css-wq-nac" :class="{ active: helpers.purpose }"></div>
      </div>
    </div>
  </div>
</template>

<script src="./DAPPwork.js" />

<style src="./DAPPwork.css" />
