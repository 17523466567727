import axiosAPI from "@/api/axios-api";
import { saveAs } from "file-saver";
import { dapp_scheme } from "../scheme/dapp-scheme";
import { defaultLogo } from "@/utils";
import { REPORT_URL } from "@/api/index";

export default {
  data() {
    return {
      chartData: {
        labels: ["Development Team"],
        datasets: [
          {
            data: [30],
            backgroundColor: [
              "#27aeef",
              "#ea5545",
              "#f46a9b",
              "#ef9b20",
              "#edbf33",
              "#ede15b",
              "#bdcf32",
              "#87bc45",
              "#b33dc6",
            ],
            hoverBackgroundColor: [
              "#27aeef",
              "#ea5545",
              "#f46a9b",
              "#ef9b20",
              "#edbf33",
              "#ede15b",
              "#bdcf32",
              "#87bc45",
              "#b33dc6",
            ],

            borderWidth: 1,
          },
        ],
      },
      lightOptions: {
        plugins: {
          legend: {
            labels: {
              color: "#0069f5",
            },
          },
        },
      },
      themes: { 0: "theme-light", 1: "theme-dark", 2: "theme-blue" },
      reportForm: null,
      notes: {
        answer: "",
        textarea: "",
      },
      selected: "",
      submitButtonMessage: "Submit",
      sendingError: false,
      avatarUrl: "",
      schemeCounter: [0, 0],
      schemeQuestionList: dapp_scheme,
      schemeCategory: {
        community: [],
        tokenomics: [],
        development_team: [],
        trading_metrics: [],
      },
      schemeProgress: [],
      categoryVisible: [1, 1, 1, 1],
      currentQuestion: [],
      answered: [],
      helpers: {
        purpose: false,
        evaluate: false,
        example: false,
        sidebar: true,
      },
      reportCreated: false,
      reportLink: "",
      beforeEncode: [],
      summaryVisible: false,
      themeState: [],
      chipData: [],
      defaultLogo: defaultLogo,
      saveInterval: null,
    };
  },
  created() {
    this.changeTheme();
    this.setupCounter();
    this.setupQuestions();
    this.changeCurrentQuestion();
    this.changeListQuestion();
    this.getAuditInfo();
    this.setupChip();
    this.setupAvatar();
    this.savingSetup();
  },
  watch: {
    summaryVisible() {
      this.reportCreated = false;
    },
  },

  mounted() {
    const value = localStorage.getItem("dapp");

    if (value) {
      const [questions, general, progress, chart] = JSON.parse(value);

      this.schemeQuestionList = questions;
      this.answered = questions;
      this.reportForm = general;
      this.schemeProgress = progress;
      this.chartData = chart;
      this.$store.commit("configureDAPP", general);
      this.clickCurrentQuestion(1);
    }
  },

  beforeUnmount() {
    clearInterval(this.saveInterval);
    console.log("unmount");
  },
  methods: {
    savingSetup() {
      this.saveInterval = setInterval(() => {
        const drafts = [
          this.answered,
          this.reportForm,
          this.schemeProgress,
          this.chartData,
        ];

        localStorage.setItem("dapp", JSON.stringify(drafts, null, 4));

        console.info("saved");
      }, 1 * 1000);
    },
    setupChip() {
      this.chipData = this.chartData.labels;
    },
    onChipAdded(e) {
      this.chipData = e.value;
    },
    onChipRemove(e) {
      const index = this.chipData.indexOf(e.value[0]);

      const data = this.chartData.datasets[0].data;

      this.chartData.datasets[0].data = data.filter((e, i) => i !== index);

      this.chipData = this.chipData.filter((e, i) => i !== index);
    },
    changeTheme() {
      this.themeState[0] < 2 ? this.themeState[0]++ : (this.themeState[0] = 0);

      localStorage.setItem("theme", this.themeState[0]);

      document.documentElement.className = this.themes[this.themeState[0]];

      let new_color = "#0f1419";

      if (this.themeState[0] === 1 || this.themeState[0] === 2) {
        new_color = "#ffffff";
      }

      this.lightOptions.plugins.legend.labels.color = new_color;
      this.chartData.labels.push("empty");
      this.chartData.labels.pop();
    },

    setupCounter() {
      this.schemeCounter[1] = this.schemeQuestionList.length;
    },
    setupQuestions() {
      this.answered = this.schemeQuestionList;
    },
    saveDraftReport() {
      this.reportForm["version"] = "4.0";

      const drafts = [
        this.answered,
        this.reportForm,
        this.schemeProgress,
        this.chartData,
      ];

      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

      const time = today.getHours() + "h-" + today.getMinutes() + "m-";

      const fileName = `${this.reportForm.project_name.toLowerCase().trim()}-${
        date + "-" + time
      }.json`;

      const fileToSave = new Blob([JSON.stringify(drafts, null, 4)], {
        type: "application/json",
      });

      saveAs(fileToSave, fileName);
    },
    handleFileUpload(event) {
      const reader = new FileReader();
      reader.readAsText(event.target.files[0]);
      reader.onload = (e) => {
        const [questions, general, progress, chart] = JSON.parse(
          e.target.result
        );

        this.schemeQuestionList = questions;
        this.answered = questions;
        this.reportForm = general;
        this.schemeProgress = progress;
        this.chartData = chart;
        this.$store.commit("configureDAPP", general);
        this.clickCurrentQuestion(1);
      };
    },
    setNotesAnswer(element) {
      this.notes.answer = element;
    },
    summaryLayout() {
      this.summaryVisible = !this.summaryVisible;
    },
    remainingQuestion() {
      let remaining = [];

      for (const element of this.answered) {
        if (element.answer === 405) {
          this.answered[element.id - 1].answer = null;
        }

        if (element.answer === null) {
          remaining.push({
            id: element.id,
            a: element.tag,
            b: element.name,
            c: element.category,
          });
        }
      }

      if (remaining.length === 0) {
        return [];
      } else {
        return remaining;
      }
    },
    splitPurpose() {
      return this.currentQuestion[0].purpose.split(/\r?\n/);
    },
    nextGlassButton() {
      this.currentQuestion[0].answer = 405;
    },
    setupAvatar() {
      const hashCode = function (s) {
        return s.split("").reduce(function (a, b) {
          a = (a << 5) - a + b.charCodeAt(0);
          return a & a;
        }, 0);
      };
      const base = this.reportForm.nickname + this.reportForm.nickname.length;

      this.avatarUrl = `https://api.dicebear.com/7.x/identicon/svg?scale=100&seed=${hashCode(
        base
      )}`;
    },
    getAuditInfo() {
      this.reportForm = this.$store.getters.getAuditInfoDAPP;
      this.reportForm["nid"] = this.createRandomString(20);
    },
    changeCurrentQuestion() {
      this.currentQuestion.push(this.schemeQuestionList[this.schemeCounter[0]]);
    },
    changeAnswerQuestion(answer) {
      this.currentQuestion[0].answer = answer;
    },
    cleanCurrentQuestion() {
      this.currentQuestion = [];
    },
    dependencyTree() {
      if (this.answered[23].answer === 0) {
        this.answered[23].answer = 0;
        this.answered[24].answer = 0;
        this.answered[25].answer = 0;
        this.answered[26].answer = 0;
        this.answered[27].answer = 0;
      }
    },
    nextAnswerQuestion() {
      this.dependencyTree();
      this.updateProgress();
      this.answered[this.schemeCounter[0]] = this.currentQuestion.pop();
      this.schemeCounter[0] += 1;
      this.changeCurrentQuestion();
    },
    backAnswerQuestion() {
      this.cleanCurrentQuestion();
      this.schemeCounter[0] -= 1;
      this.currentQuestion.push(this.answered[this.schemeCounter[0]]);
    },
    clickCurrentQuestion(num) {
      let number = num - 1;
      for (const n in this.answered) {
        if (n.id === number) {
          this.cleanCurrentQuestion();
          this.currentQuestion.push(this.answered[number]);
          this.schemeCounter[0] = this.currentQuestion[0].id - 1;
        }
        if (n.id != number) {
          this.cleanCurrentQuestion();
          this.currentQuestion.push(this.schemeQuestionList[number]);
          this.schemeCounter[0] = this.currentQuestion[0].id - 1;
        }
      }
    },
    addAnsweredQuestion() {
      this.answered[this.schemeCounter[0]] = this.currentQuestion[0];
    },
    createNewReport() {
      this.dependencyTree();
      this.updateProgress();
      this.addAnsweredQuestion();
      this.sendNewReport();
    },
    updateProgress() {
      return this.currentQuestion[0].answer
        ? this.schemeProgress.push(this.currentQuestion[0].id)
        : false;
    },
    async sendNewReport() {
      this.beforeEncode = [];

      this.sendingError = false;

      this.reportLink = "";

      this.reportForm["charts"] = {
        tokenomics: {
          labels: this.chartData.labels,
          values: this.chartData.datasets[0].data,
          ref: [12, 13, 14, 15],
        },
        community: {
          twitter: {
            id: 17,
            value: this.answered[17 - 1].input || "0",
          },
          reddit: {
            id: 19,
            value: this.answered[19 - 1].input || "0",
          },
          telegram: {
            id: 21,
            value: this.answered[21 - 1].input || "0",
          },
          discord: {
            id: 23,
            value: this.answered[23 - 1].input || "0",
          },
        },
      };

      this.reportForm["notes"] = this.notes;

      this.answered.forEach((e) => {
        this.beforeEncode.push({
          id: e.id,
          answer: e.answer,
          textarea: e.textarea,
          input: e.input || "",
        });
      });

      try {
        const params = {
          scheme: "dapp",

          project_name: this.reportForm.project_name.trim().toLowerCase(),

          description: this.reportForm.description.trim().toLowerCase(),

          website: this.reportForm.website.trim().toLowerCase(),

          category: this.reportForm.category.trim().toLowerCase(),

          symbol: this.reportForm.symbol.trim().toLowerCase(),

          repository: this.reportForm.repository.trim().toLowerCase(),

          author: this.reportForm.nickname.trim().toLowerCase(),

          project_logo: this.reportForm.project_logo,

          charts: this.reportForm.charts,

          notes: this.reportForm.notes,

          nid: this.reportForm.nid,

          data: this.beforeEncode,
        };

        console.log(params);

        this.submitButtonMessage = "Uploading";

        document.getElementById("create-report").disabled = true;

        await axiosAPI
          .post("/api/dyortool/create-dapp", params)
          .then((response) => {
            console.log(response.data);
            this.reportCreated = true;
            this.reportLink = `${REPORT_URL}/report/${response.data.payload[0].id}`;
            this.submitButtonMessage = "Create Report";
          })
          .catch((error) => {
            console.error(error.response);
            this.sendingError = error.response.data.errors;
            this.submitButtonMessage = "Create Report";
            document.getElementById("create-report").disabled = false;
          });
      } catch (error) {
        console.error(error);
      }
    },

    checkCompleteness() {
      let isComplete = false;
      let checkAnswers = [];
      this.answered.forEach((e) => checkAnswers.push(e.answer));
      if (checkAnswers.includes(null)) {
        isComplete = false;
      }
      if (!checkAnswers.includes(null)) {
        isComplete = true;
      }
      return isComplete;
    },
    createRandomString(length) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    },
    removeByIndex(array, item) {
      let e = array.indexOf(item);

      if (e !== -1) {
        array.splice(e, 1);
      }
    },
    backToWorksteps() {
      this.currentQuestion[0].answer = null;
      this.removeByIndex(this.schemeProgress, this.currentQuestion[0].id);
    },
    knowWorkProgress(step) {
      let acc = 0;
      this.schemeCategory[step].forEach((s) => {
        this.schemeProgress.includes(s.id) ? (acc += 1) : false;
      });
      return acc;
    },
    changeListQuestion() {
      for (const element of this.schemeQuestionList) {
        switch (element.category) {
          case "Development Team":
            this.schemeCategory["development_team"].push({
              id: element.id,
              name: element.name,
            });
            break;
          case "Tokenomics":
            this.schemeCategory["tokenomics"].push({
              id: element.id,
              name: element.name,
            });
            break;
          case "Community":
            this.schemeCategory["community"].push({
              id: element.id,
              name: element.name,
            });
            break;
          case "Trading Metrics":
            this.schemeCategory["trading_metrics"].push({
              id: element.id,
              name: element.name,
            });
            break;

          default:
            break;
        }
      }
    },
  },
};
