<template>
  <div class="css-cp-wrap">
    <template v-if="isLoading">
      <div class="k0c">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </template>
    <header class="css-cp-hdc">
      <router-link to="/" style="display: flex; align-items: baseline">
        <img src="../assets/white-logo.svg" alt="" />
      </router-link>
    </header>
    <div class="css-cp-ndw">
      <div class="css-cp-ndf">
        <router-link class="logo-blue" to="/">
          <img src="../assets/logo.svg" alt="" />
        </router-link>
        <div class="css-cp-nxs">
          <div class="css-cp-nsk">
            <div class="css-cp-sdt">
              Fill out the following form with details of the project you want.
            </div>
          </div>
        </div>
        <div class="css-cp-faw">
          <div class="css-cp-nsc">
            <div class="css-cp-nst">
              Name
              <span class="css-cp-xsa" :class="{ active: errors.project_name }"
                >Must not be empty or greater than 50 length</span
              >
            </div>
            <input
              id="tableTradeSearch"
              name="query"
              autocomplete="off"
              class="css-cp-nii"
              type="text"
              placeholder="Project's name"
              v-model="form.project_name"
            />
            <template v-if="dropdown">
              <div class="css-t-h-wrp" id="tableD">
                <div
                  class="css-trade-history-scl"
                  @click="deployDropdown(false)"
                ></div>
                <div class="css-trade-history-sub">
                  <span></span>
                  <span>Powered by builtoncardano</span>
                </div>
                <div class="css-trade-history-tablew">
                  <table>
                    <thead>
                      <tr class="css-trade-history-txz">
                        <th
                          v-for="key in columns"
                          :key="key"
                          @click="sortBy(key)"
                          class="css-trade-history-tzx"
                          :class="{ active: sortKey == key }"
                        >
                          {{ capitalize(key) }}
                          <span
                            class="arrow"
                            :class="sortOrders[key] > 0 ? 'asc' : 'dsc'"
                          >
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="entry in filteredCoins"
                        :key="entry"
                        @click="handleSelection(entry)"
                      >
                        <td v-for="key in columns" :key="key">
                          <template v-if="key === 'logo'">
                            <img
                              style="width: 50px; height: 50px"
                              :src="entry[key]"
                              alt=""
                            />
                          </template>
                          <template v-if="key !== 'logo'">
                            {{ entry[key] }}
                          </template>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
          </div>
          <div class="css-cp-nsi">
            <div class="css-cp-nst">
              Description
              <span class="css-cp-xsa" :class="{ active: errors.description }"
                >Must not be empty or greater than 200 length</span
              >
            </div>
            <input
              class="css-cp-nii"
              v-model="form.description"
              type="text"
              placeholder="Short description"
            />
          </div>

          <div class="css-cp-nsi">
            <div class="css-cp-nst">
              Website
              <span class="css-cp-xsa" :class="{ active: errors.website }"
                >Must be url or greater than 300 length</span
              >
            </div>
            <input
              class="css-cp-nii"
              v-model="form.website"
              type="text"
              placeholder="www.example.com"
            />
          </div>

          <div class="css-cp-nsi">
            <div class="css-cp-nst">
              Category
              <span class="css-cp-xsa" :class="{ active: errors.category }"
                >Must not be empty or greater than 50 length</span
              >
            </div>
            <input
              class="css-cp-nii"
              v-model="form.category"
              type="text"
              placeholder="Project Category"
            />
          </div>

          <div class="css-cp-nsi">
            <div class="css-cp-nst">
              Symbol
              <span class="css-cp-xsa" :class="{ active: errors.symbol }"
                >Must not be empty or greater than 50 length</span
              >
            </div>
            <input
              class="css-cp-nii"
              v-model="form.symbol"
              type="text"
              placeholder="Project's Symbol"
            />
          </div>

          <div class="css-cp-nsi">
            <div class="css-cp-nst">
              Repository
              <span class="css-cp-xsa" :class="{ active: errors.repository }"
                >Must not be empty or greater than 200 length</span
              >
            </div>
            <input
              class="css-cp-nii"
              v-model="form.repository"
              type="text"
              placeholder="Project's Repository"
            />
          </div>
          <div class="css-cp-nsi">
            <div class="css-cp-nst">
              Your nickname
              <span class="css-cp-xsa" :class="{ active: errors.nickname }"
                >Must not be empty or greater than 50 length</span
              >
            </div>
            <input
              class="css-cp-nii"
              v-model="form.nickname"
              type="text"
              placeholder="Your nickname for the report..."
            />
          </div>
          <div class="css-cp-nsi">
            <div class="css-cp-nst">
              Logo
              <span class="css-cp-xsa" :class="{ active: errors.project_logo }"
                >Please upload the logo of the project</span
              >
            </div>
            <div class="css-cp-sla">
              <my-upload
                field="img"
                @crop-success="cropSuccess"
                @crop-upload-success="cropUploadSuccess"
                @crop-upload-fail="cropUploadFail"
                v-model="isImageUploading"
                :width="80"
                :height="80"
                noSquare
                langType="en"
                img-format="png"
              />

              <div class="css-cp-ixw">
                <div class="css-cp-stx" :class="{ active: form.project_logo }">
                  <div class="css-cp-str" id="canvas-wrapper">
                    <template v-if="!isFetching">
                      <img
                        :src="this.form.project_logo || defaultLogo"
                        alt=""
                      />
                    </template>

                    <template v-if="isFetching">
                      <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="css-cp-bwp">
                  <button class="css-upload-button" @click="uploadLogo">
                    Upload logo
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="css-cp-nsl"></div>
        </div>
        <button class="css-cp-asa" @click="checkDataIntegrity()">
          Let's go !
        </button>
        <template v-if="disclaimer === false">
          <div class="css-cp-cgj">
            <span class="css-cp-cjs">DISCLAIMER</span>
            <span>
              DYOR Tool is meant to help users learn about investing. Using the
              tool does not guarantee that your investments will be profitable
              or that the information will be accurate or comprehensive. It is
              meant for informational purposes only. The information contained
              in the DYOR Tool is not intended as, and shall not be understood
              or construed as, financial advice. The makers of the tool are not
              financial advisors or attorneys, and the information contained on
              the website is not a substitute for financial advice from a
              professional who is aware of the facts and circumstances of your
              individual situation. The creators of the tool accept no liability
              whatsoever for any loss or damage you may incur. We expressly
              recommend that you seek advice from a professional before making
              any investments.
            </span>
            <div class="css-cp-csj">
              <button @click="changeDisclaimer(null)">Decline</button>
              <button @click="createNewReport()">Accept</button>
            </div>
          </div>
        </template>
        <div class="css-cp-csh" />
      </div>
    </div>
  </div>
</template>

<script>
import myUpload from "vue-image-crop-upload-responsive";
import validator from "validator";
import { defaultLogo } from "@/utils";

const columns = ["logo", "name", "description"];

const sorted = {};
columns.forEach(function (key) {
  sorted[key] = 1;
});

export default {
  components: {
    "my-upload": myUpload,
  },
  mounted() {
    // this.setupDropdown();
  },
  created() {
    this.updateData();
  },
  data() {
    return {
      errors: {
        project_name: false,
        description: false,
        website: false,
        category: false,
        symbol: false,
        repository: false,
        nickname: false,
        project_logo: false,
      },
      form: {
        project_name: "",
        description: "",
        website: "",
        category: "",
        symbol: "",
        repository: "",
        nickname: "",
        project_logo: "",
      },
      disclaimer: null,
      isImageUploading: false,
      projectData: [],
      columns: columns,
      sortKey: "",
      dropdown: false,
      isLoading: true,
      isFetching: false,
      defaultLogo: defaultLogo,
    };
  },
  methods: {
    updateData() {
      this.projectData = [];

      this.isLoading = false;
    },
    uploadLogo() {
      this.isImageUploading = !this.isImageUploading;
    },
    cropSuccess(img, field) {
      this.form.project_logo = img;
      console.log("Image loaded", field);
    },

    cropUploadSuccess(jsonData, field) {
      console.log(jsonData);
      console.log("field: " + field);
    },
    cropUploadFail(status, field) {
      this.form.project_logo = "";
      console.log(status);
      console.log("field: " + field);
    },
    setupDropdown() {
      let timer;

      const wait_time = 500;

      const dropdown_input = document.querySelector("#tableTradeSearch");

      dropdown_input.addEventListener("keyup", () => {
        clearTimeout(timer);

        timer = setTimeout(() => {
          this.deployDropdown(true);

          dropdown_input.blur();
          dropdown_input.focus();
        }, wait_time);
      });

      dropdown_input.addEventListener("click", () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          this.deployDropdown(true);
        }, wait_time);
      });
    },
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
    },
    /*
    handleSelection(element) {
      this.deployDropdown(false);
      this.form.project_name = element.name;
      this.form.description = element.description;
      this.updateLogo(element.name);
    },

    updateLogo(element) {
      this.isFetching = true;

      this.$nextTick(() => {
        setTimeout(async () => {
          try {
            const response = await axios.get(
              `https://api.dyortool.io/1.1/logo/report-logo/${element}`
            );
            this.form.project_logo = response.data.logo;
            this.isFetching = false;
          } catch (error) {
            console.log(error);
          }
        }, 1000);
      });
    },
    */
    deployDropdown(b) {
      this.dropdown = b;
    },
    checkDataIntegrity() {
      if (this.validateFormData()) {
        this.disclaimer = false;
      }
    },
    changeDisclaimer(e) {
      this.disclaimer = e;
    },
    validateFormData() {
      this.errors = {
        project_name: !validator.isLength(this.form.project_name, {
          min: 1,
          max: 50,
        }),
        description: !validator.isLength(this.form.description, {
          min: 1,
          max: 200,
        }),
        website: !(
          validator.isURL(this.form.website, {
            protocols: ["https", "http"],
            require_tld: true,
            require_protocol: true,
            require_host: true,
            require_port: false,
            require_valid_protocol: true,
            allow_underscores: true,
            allow_trailing_dot: true,
            allow_protocol_relative_urls: true,
            allow_fragments: true,
            allow_query_components: true,
            disallow_auth: false,
            validate_length: true,
          }) && validator.isLength(this.form.website, { min: 1, max: 300 })
        ),
        category: !validator.isLength(this.form.category, { min: 1, max: 50 }),
        symbol: !validator.isLength(this.form.symbol, { min: 1, max: 50 }),
        repository: !validator.isLength(this.form.repository, {
          min: 1,
          max: 200,
        }),
        nickname: !validator.isLength(this.form.nickname, {
          min: 1,
          max: 50,
        }),
        project_logo: false,
      };

      console.log(this.errors);

      if (!this.form.project_logo) {
        this.errors.project_logo = true;
      }

      if (!this.isFetching) {
        return !Object.values(this.errors).includes(true) ? true : false;
      }
    },
    createNewReport() {
      localStorage.removeItem("dapp");
      this.$store.commit("configureDAPP", this.form);
      this.$router.push("/dapp-scheme");
    },
  },
  computed: {
    filteredCoins() {
      const sortKey = this.sortKey;
      const filterKey =
        this.form.project_name && this.form.project_name.toLowerCase();
      const order = this.sortOrders[sortKey] || 1;
      let projectData = this.projectData;
      if (filterKey) {
        projectData = projectData.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
          });
        });
      }
      if (sortKey) {
        projectData = projectData.slice().sort(function (a, b) {
          a = a[sortKey];
          b = b[sortKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      return projectData;
    },
    sortOrders() {
      const columnSortOrders = {};

      this.columns.forEach(function (key) {
        columnSortOrders[key] = 1;
      });

      return columnSortOrders;
    },
  },
};
</script>
<style scoped>
*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-track {
  background: #fafafa;
}

*::-webkit-scrollbar-thumb {
  background: var(--text-b);
}

*::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
