import axiosAPI from "@/api/axios-api";
import { REPORT_URL } from "@/api/index";

const columns = [
  "project",
  "scheme",
  "total_score",
  "total_percentage",
  "author",
  "date",
];

export default {
  data() {
    return {
      filterKey: "",
      columns,
      sortOrders: columns.reduce((o, key) => ((o[key] = 1), o), {}),
      sortKey: "",
      navTab: "library",
      library: "library",
      reportData: [],
      isLoading: true,
    };
  },
  created() {
    this.updateData();
  },
  methods: {
    updateData() {
      this.$nextTick(() => {
        setTimeout(async () => {
          try {
            const response = await axiosAPI.get("/api/dyortool/get-reports");

            const payload = response.data.payload;

            this.reportData = payload.map((e) => {
              return {
                id: e.id,
                project: e.project,
                scheme: e.scheme.toUpperCase(),
                total_percentage: e.total_percentage,
                total_score: e.total_score,
                author: e.author,
                date: new Date(e.date).toISOString().split("T")[0],
              };
            });

            this.isLoading = false;
          } catch (error) {
            console.error(error);
          }
        }, 0);
      });
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
    },
    changeTab(e) {
      this.navTab = e;
    },
    headify(str) {
      const value = str.replace("_", " ");
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    handleSelection(e) {
      window.open(`${REPORT_URL}/report/${e.id}`, "_blank");
    },
  },
  computed: {
    computedReports() {
      const sortKey = this.sortKey;

      const filterKey = this.filterKey && this.filterKey.toLowerCase();

      const order = this.sortOrders[sortKey] || 1;

      let reportData = this.reportData;

      if (filterKey) {
        reportData = reportData.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
          });
        });
      }
      if (sortKey) {
        reportData = reportData.slice().sort(function (a, b) {
          a = a[sortKey];
          b = b[sortKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      return reportData;
    },
  },
};
